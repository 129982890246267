export default {
  "app": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foto's"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vragen"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menu"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinees Restaurant, Restaurant"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontich, Belgie"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ervaar de smaak van een echt familierestaurant."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Fu is een familierestaurant gelegen in het hartje van Kontich en bestaat reeds meer dan 15 jaar.\n \n De familie Wang die deze zaak uitbaat komt van Wenzhou, China. In het Wenzhounees betekent 'Hong Fu, 鸿福, 'iedereen gelukkig'.\n \n Alle gerechten worden altijd vers en met veel liefde bereidt. \n Hopelijk tot binnenkort!"])},
    "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u reserveren, een bestelling plaatsen om mee te nemen of heeft u een andere vraag?"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["op met ons"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij zijn met jaarlijks verlof van 5 maart t.e.m. 31 maart. Dinsdag 1 april zijn we weer open vanaf 12 uur 's middags. \n Hopelijk zien we jullie dan weer terug!"])}
  },
  "contact": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U wilt bestellen, wilt reserveren of heeft een vraag?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacteer ons en wij helpen u graag verder"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kan ons ook via Facebook contacteren door rechtsonder op het chat icoontje te drukken."])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een vraag? Bekijk zeker onze"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veelgestelde vragen"])},
    "days1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma-woe, vrijdag en zaterdag"])},
    "days2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag: gesloten"])},
    "days3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zon- en feestdagen"])},
    "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereer route"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk reviews"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een vraag?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misschien kunt u hier al een antwoord vinden."])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebt u nog een andere vraag?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met ons:"])},
    "q1": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke betaalmogelijkheden zijn er?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt bij ons betalen met cash, bancontact of met QR code."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: Visa, Mastercard, buitenlandse kaarten en ApplePay werken niet bij ons."])}
    },
    "q2": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveren jullie aan huis?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee, wij leveren spijtig genoeg niet aan huis. \n U kunt bij ons afhalen of ter plekke komen eten."])}
    },
    "q3": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat zijn jullie openingsuren?"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma-woe, vrijdag en zaterdag"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag: gesloten"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zon- en feestdagen"])}
    },
    "q4": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zijn jullie open op feestdagen?"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, wij zijn geopend op"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feestdagen."])}
    },
    "q5": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkopen jullie cadeaukaarten?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt een cadeaukaart kopen aan de toog."])}
    },
    "q6": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunnen we een bestelling via mail of Facebook plaatsen?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u dit minstens 24u op voorhand doet kunt u een bestelling altijd via mail of Facebook doorgeven. Wilt u nog voor dezelfde dag bestellen? \n Bel ons even op, dan kunnen we garanderen dat u bestelling op tijd in de keuken raakt. \n\n Het kan namelijk zeer druk worden waardoor er wachttijden kunnen ontstaan en waardoor wij ook niet op u bericht kunnen antwoorden."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: indien we een bestelling niet bevestigen binnen de 25 minuten, is deze niet gegarandeerd. Bel in dat geval dus zeker even."])}
    }
  },
  "menu": {
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De afgebeelde prijzen gelden enkel voor afhaal."])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen?"])},
    "days1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma-woe, vrijdag en zaterdag"])},
    "days2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag: gesloten"])},
    "days3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zon- en feestdagen"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menus"])}
  }
}